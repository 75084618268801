.filter-button {
  background: none;
  border: none;
  cursor: pointer;
}

.filter-button .fas {
  margin-right: 5px; /* Space between icon and any text if you add text */
}

.fa-solid{
  font-size: auto;
}