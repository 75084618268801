body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding: 0%;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px 20px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
}

.navbar div > div {
  display: flex;
  gap: 8px;
  text-decoration: none;
  color: #333;
  margin: 0 15px;
  padding: 8px 12px;
  border-radius: 20px;
  transition: background-color 0.3s;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.navbar div > div:hover {
  background-color: #e0e0e0;
}

.light-grey-background {
  width: 100%; /* Full width */
  background-color: #f9f9f9; /* Light grey background */
  /* Add any other styling as needed, e.g., padding, margin */
}

.slide-up-bottom {
  position: fixed;
  left: 50%;
  bottom: -100px; /* Initially off-screen */
  transform: translateX(-50%);
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 50px; /* Pill shape */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: bottom 0.5s ease-in-out;
  z-index: 1000;
  width: 25vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #464df2;
}

.slide-up-bottom.visible {
  bottom: 20px; /* Slide up to visible position */
}

.slide-up-bottom .data-text,
.slide-up-bottom button {
  flex: 1; /* Allow both children to grow and take up equal space */
}

.slide-up-bottom .data-text {
  text-align: left; /* Align text to the left */
  margin-right: auto; /* Push everything else to the right */
}

.data-text {
  margin-left: 20px; /* Push everything else to the right */
}

.slide-up-bottom button {
  background-color: #464DF2; /* Dark blue background for the button */
  color: white;
  border: none;
  padding: 18px;
  border-radius: 30px; /* Rounded corners for the button */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Transition for button interaction */
  margin-left: auto; /* Push the button to the right */
  max-width: 50%;
  font-weight: bold;
}

.slide-up-bottom button:hover {
  background-color: #3630A3; /* Slightly darker on hover */
}

.slide-up-bottom .data-text,
.slide-up-bottom .data-number {
  color: black; /* Set text color to black */
}

.slide-up-bottom .data-number {
  font-size: 1.5rem; /* Larger font size for the number */
  font-weight: bold; /* Bold font weight */
}