.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ag-grid-popover {

  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(47, 74, 91);
  border: 1px solid blue;
  border-radius: 20px;
  margin-left: 2rem;
  margin-right: 2rem;
}

a, a:visited, a:hover, a:active {
  color: blue;
}

#filter-text-box {
  border-radius: 32px;
  padding: .6rem;
  box-sizing: 0px 0px 32px 10px #464DF23D;
  background-color: white;
  border: 1px solid lightgrey;
}

.hidden{
  display: none;
}