/* Style for the container div */
.my-datasets-info {
  margin-left: 20px;
  padding: 5px; /* Padding around the content */
  text-align: center; /* Center the text */
}

/* Style for the paragraph */
.my-datasets-info p {
  margin: 0; /* Remove default margin */
  font-weight: bold; /* Bold font for emphasis */
  margin-bottom: 5px;
}

/* Style for the span */
.my-datasets-info span {
  padding: 5px; /* Padding around the number */
  color: white; /* Dark color for the number */
  font-size: 1em; /* Larger font size for visibility */
  background-color: #464DF2;
  border-radius: 15px;
  
}

.bg_icon{
  cursor: pointer;
}

/* For the modal */
.modal-overlay {
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-container {
  position: fixed; /* Ensure the modal itself is also fixed */
  top: 20%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -100%); /* Adjust for the modal's own size */
  background-color: white;
  padding: 1rem;
  border-radius: 32px;
  border-width: 1px;
  box-shadow: 0px 0px 32px 0px #464df23d;
  border-color: #f3f3fa;
  width: 25vw;
}

.close-icon {
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Styles for the modal content */
.my-modal {
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-modal h2 {
  color: black;
  letter-spacing: 1px;
  justify-content: center;
}

.my-modal p {
  color: #2f4a5b;
  justify-content: center;
}

.my-modal h3 {
  color: #2f4a5b;
  justify-content: center;
}

.my-modal table {
  border-collapse: collapse;
  width: 100%;
}

.my-modal th,
.my-modal td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
