.filters {
  padding-top: 10px;
  display: flex; /* Align children horizontally */
  align-items: center; /* Center children vertically */
  flex-wrap: nowrap; /* Prevent wrapping onto a new line */
  gap: 10px; /* Optional: Adds space between children */
}

.filterdiv {
  display: flex;
  align-items: center;
  height: 29px;
  border-radius: 30px;
  border-color: lightgrey;
  border-width: 1px;
  border-style: solid;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}

.filterdivdisabled {
  display: flex;
  align-items: center;
  height: 29px;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.filtertext {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #2f4a5b;
}
.filtertoggle {
  position: relative;
  display: inline-block;
  border-radius: 20px;
  border-color: lightgrey;
  border-width: 1px;
  border-style: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  width: 10px;
}
.filterinput {
  opacity: 0;
  width: 0;
  height: 0;
}
.filterspan {
  position: absolute;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  border-radius: 30px;
  padding: 10px;
  background-color: #c9d0d4;
  color: #2f4a5b;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.filterspan:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s;
  color: #2f4a5b;
  font-size: 14px;
  font-weight: 400;
}
input:checked + span {
  background-color: rgba(70, 77, 242, 1);
}
input:checked + span:before {
  transform: translateX(17px);
}
