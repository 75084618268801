.ag-theme-alpine {
    --ag-selected-row-background-color: #46F24D14;
}

.ag-horizontal-left-spacer {
    visibility: hidden
}

.selectioncolumn {

    background-color: #E0E1FF;
    color:#2F4A5B;

}

.selectioncolumn:hover {

    background-color: #CDCFFA !important;

}


.datasetcolumn {

    background-color: #EAD4FF;
    color:#2F4A5B;

}

.datasetcolumn:hover {

    background-color: #E0C7F8 !important;

}


.keywordcolumn {

    background-color: #F3D0EC;
    color:#2F4A5B;

}

.keywordcolumn:hover {

    background-color: #EDBCE2 !important;

}

.articlecolumn {

    background-color: #FFD3D3;
    color:#2F4A5B;

}

.articlecolumn:hover {

    background-color: #FBC5C5 !important;

}

.samplingcolumn {

    background-color: #FFE5CD;
    color:#2F4A5B;

}

.samplingcolumn:hover {

    background-color: #FADEC5 !important;

}


::-webkit-scrollbar {
    height: 10px;
    width:10px;
    background-color:white;
}
 
::-webkit-scrollbar-track {
    background-color:white;
    border-radius: 5px;
}
 
::-webkit-scrollbar-thumb {

    border-radius: 5px;
    background-color: #2F4A5B;

}

::-webkit-scrollbar-thumb:hover {

    border-radius: 5px;
    background-color: #CFD5D9;

}

.ag-icon, .ag-icon-contracted {
    color: #2F4A5B;
}


.ag-large-text-input {
    padding: 1px;
}