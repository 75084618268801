.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  width: 100%; /* Full width */
  height: 83vh; /* 85% of the viewport height */
  padding: 10px; /* Optional: padding around the flex container */
  box-sizing: border-box; /* Includes padding in the width and height calculations */
  align-content: flex-start; /* Aligns wrapped lines at the start of the container */
  justify-content: space-between; /* Equal space between flex items */
}

.flex-item {
  background-color: white;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  width: 49.5%; /* Each item takes up half the container width */
  height: 49%; /* Each item takes up half the container height minus the gap */
  padding: 10px; /* Padding inside each flex item */
  margin: 2px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flex-item-pie {
  /* Existing styles */
  display: flex; /* Make the flex-item itself a flex container */
  flex-direction: column; /* Stack children vertically */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .flex-item {
    width: 100%; /* Each item takes up the full container width */
    height: 50%; /* Each item takes up a quarter of the container height minus the gap */
    padding: 10px;
  }
}
