.modalMain {
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalTitle {
  letter-spacing: 1px;
  justify-content: center;
}

.modalInfo {
  color: #2f4a5b;
  justify-content: center;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #464df214;
  border-radius: 24px;
  color: #2f4a5b;
  margin-top: 20px;
}

.modalFooter p {
  text-align: center;
  align-self: center;
  font-weight: 500;
  padding: 1.5rem;
}

.contactButton {
  display: flex;
  align-items: center;
  background-color: #464df2;
  color: white;
  border-radius: 20px;
  padding: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.creditsButton {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}

.infoCircle {
  margin-right: 0.5rem;
}

.creditSpan {
  color: black;
}

.greySpan {
  color: grey;
  margin-left: 1rem;
}

.selectedSpan {
  height: 20px;
  width: 44px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(47, 74, 91, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalWrapper {
  position: relative;
  background-color: white;
  padding: 1rem;
  border-radius: 32px;
  box-shadow: 0px 0px 32px 0px #464df23d;
  border-color: #f3f3fa;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.modalMain {
  .modalHeader,
  .modalContent,
  .modalFooter {
    margin-bottom: 1rem;
  }

  .modalInfo {
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  .contactInfo {
    margin-bottom: 1rem;
  }

  .contactButton {
    display: flex;
    align-items: center;
    background-color: #464df2;
    color: white;
    border-radius: 20px;
    padding: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
}

.modal-container2 {
  position: fixed; /* Fixed position relative to the viewport */
  top: 50%; /* Position it 50% from the top */
  left: 50%; /* Position it 50% from the left */
  transform: translate(-50%, -50%); /* Move it back 50% of its own width and height to center */
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  box-shadow: 0px 0px 32px 0px #464df23d;
  border: 1px solid #f3f3fa;
  z-index: 9999; /* Ensure it's above other content */
  width: 33vw; /* Maximum width */
  box-sizing: border-box; /* Include padding and border in the width */
}

.modal-header {
  color: black;
  letter-spacing: 1px;
}

.modal-text {
  color: #2f4a5b;
  text-align: center;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #464df214;
  border-radius: 24px;
  color: #2f4a5b;
  margin-top: 20px;
}

.info-text {
  text-align: center;
  font-weight: 500;
  padding: 1.5rem;
}

.contact-button {
  display: flex;
  align-items: center;
  background-color: #464df2;
  color: white;
  border-radius: 20px;
  padding: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.close-icon {
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Additional styles for button */
.my-credits-button {
  display: flex;
  align-items: center;
  background-color: #464df214; /* Default color */
  color: grey; /* Default text color */
  border-radius: 20px;
  padding: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 1rem;
}

.my-credits-button.clicked {
  background-color: #464df2; /* Color when clicked */
  color: white; /* Text color when clicked */
}

.credit-details {
  color: black; /* Default text color */
  margin-left: 1vw;
  font-size: 16px;
}

.credit-details.grey {
  color: grey; /* Grey text color */
}

.credit-details.white {
  background-color: #464df2;
  color: white;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 44px;
}
