.resetbutton {
    width: 30%; /* or the width you prefer */
    padding: 5px; /* Adjust padding to align text inside the search bar */
    border: 1px solid #ccc; /* Light grey border */
    outline: none; /* Removes the default focus outline */
    border-radius: 15px; /* Large border-radius to create the pill shape */
    font-size: 12px; /* Adjust font size as needed */
    transition: all 0.3s ease; /* Smooth transition for focus effect */
    background-color: white; /* Updated background color */
    color: black; /* Updated font color */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .resetbutton .fas {
    margin-right: 5px; /* Adjust spacing between icon and text */
  }
  
  .resetbutton.active {
    background-color: blue;
    color: white; /* Text color changes to white when active */
  }