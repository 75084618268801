.upload-button {
    /* your button styles */
    background-color: white;
    color: #464DF2;
}

.upload-button:hover {
    background-color: #464DF2;
    color: white;
}

.modal-content {
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2f4a5b;
}

.modal-content h2 {
    letter-spacing: 1px;
}

.dropzone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #464DF214;
    border-radius: 24px;
    border: 2px dashed rgba(70, 77, 242, 0.08);
    margin-top: 20px;
}

.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
}

.contact-text {
    text-align: center;
    align-self: center;
    font-weight: 500;
    font-size: 16px;
    width: 450px;
    margin-top: 30px;
}

.contact-email {
    color: #464DF2;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-wrapper {
    position: relative;
    background-color: white;
    padding: 1rem;
    border-radius: 32px;
    border-width: 1px;
    box-shadow: 0px 0px 32px 0px #464DF23D;
    border-color: #F3F3FA;
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

/* Existing styles for upload button can remain here */
