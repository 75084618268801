/* MySelectionButton.module.css */
.centeredDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centeredText {
  text-align: center;
  margin: 0.5rem 0;
  color: #2f4a5b;
}

.bigText {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #2f4a5b;
  margin: 0.2rem 0;
}

.smallText {
  text-align: center;
  color: #2f4a5b8f;
  margin: 0.5rem 0;
}

.commonButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}

.modal {
  background-color: white;
  padding: 1rem;
}

.buttonPrimary {
  background-color: #464df2;
  color: white;
  margin-top: 1rem;
  width: 214px;
}

.modalInfo {
  background-color: #464df214;
  border-radius: 24px;
  margin-top: 1rem;
  padding: 1rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modalContainer {
  position: relative;
  background-color: white;
  padding: 1rem;
  border-radius: 32px;
  box-shadow: 0px 0px 32px 0px #464df23d;
  border-color: #f3f3fa;
}

.modal-header {
  color: #2F4A5B;
  letter-spacing: 1px;
}

.modal-text {
  text-align: center;
  margin: 0.5rem 0;
  color: #2F4A5B;
}

.modal-number {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #2F4A5B;
  margin: 0.2rem 0;
}

.modal-small-text {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #2F4A5B;
  margin: 0.2rem 0;
}

.modal-credits {
  text-align: center;
  color: #2F4A5B8F;
  margin: 0.5rem 0;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #464DF2;
  color: white;
  border-radius: 20px;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
  width: 214px;
  margin: auto;
}

.submit-button-disabled {
  background-color: grey;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #464DF214;
  border-radius: 24px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.info-text {
  text-align: center;
  align-self: center;
  font-weight: 500;
  color: #2F4A5B;
}

.email-link {
  color: #464DF2;
  text-decoration: underline;
}

.selection-button {
  display: flex;
  align-items: center;
  background-color: #464DF2;
  color: white;
  border-radius: 20px;
  padding: 1rem;
  border: none;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
}

.selection-button-disabled {
  background-color: #2F4A5B14;
}

.modal-overlay {
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-container {
  position: fixed; /* Ensure the modal itself is also fixed */
  top: 20%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -100%); /* Adjust for the modal's own size */
  background-color: white;
  padding: 1rem;
  border-radius: 32px;
  border-width: 1px;
  box-shadow: 0px 0px 32px 0px #464df23d;
  border-color: #f3f3fa;
  width: 25vw;
}

.close-icon {
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
}

.my-datasets-info {
  margin-left: 20px;
  padding: 5px; /* Padding around the content */
  text-align: center; /* Center the text */
}

/* Style for the paragraph */
.my-datasets-info p {
  margin: 0; /* Remove default margin */
  font-weight: bold; /* Bold font for emphasis */
  margin-bottom: 10px;
  color: #464df2 ;
}

/* Style for the span */
.my-datasets-info span {
  padding: 5px; /* Padding around the number */
  color: white; /* Dark color for the number */
  font-size: 1em; /* Larger font size for visibility */
  background-color: #464DF2;
  border-radius: 15px;
  
}