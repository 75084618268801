.inner-flex-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill the height of the flex-item */
  width: 100%; /* Fill the width of the flex-item */
  position: relative;
}

/* TOP ROW CSS */

.top-row {
  display: flex;
  justify-content: space-between;
  height: 10%; /* 10% of the parent flex-item's height */
  width: 100%; /* Full width */
  box-sizing: border-box;
}

.dropdown {
  height: 100%; /* Fill the height of the flex-item */
  width: auto; /* adjust width as needed */
  font-size: 13px; /* adjust font size as needed */
  padding: 5px 10px; /* adjust padding for a nicer appearance */
  border: 1px solid #ccc; /* adjust border style as needed */
  border-radius: 10px; /* rounded corners */
}

.right-aligned-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Space between elements */
}

.pill-search {
  width: 30%; /* or the width you prefer */
  padding: 5px 10px; /* Adjust padding to align text inside the search bar */
  border: 1px solid #ccc; /* Light grey border */
  outline: none; /* Removes the default focus outline */
  border-radius: 15px; /* Large border-radius to create the pill shape */
  font-size: 14px; /* Adjust font size as needed */
  transition: all 0.3s ease; /* Smooth transition for focus effect */
}

.icon-menu {
  position: relative;
  cursor: pointer; /* Change cursor to indicate clickable area */
}

.filter-modal {
  position: absolute;
  top: 100%; /* Below the button */
  left: 50%; /* Start at the half-width of the parent */
  margin-top: 10px;
  transform: translateX(-50%); /* Center it exactly */
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
  padding: 20px;
  font-size: 14px;

}

.filter-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px; /* Adjust the padding as needed */
  margin-bottom: 10px; /* Adjust the margin as needed */
}

.filter-menu-header h3 {
  color: #BFBFBF; /* Light grey color */
  margin: 0;
}

.filter-menu-header button {
  background: none;
  border: none;
  font-size: 1rem; /* Adjust size as needed */
  color: #BFBFBF; /* Light grey color */
  cursor: pointer;
}

.filter-menu-header button:hover,
.filter-menu-header button:focus {
  color: #a9a9a9; /* Darker grey on hover/focus for better UI feedback */
}

/* Optional: if you want to use a font awesome icon for the close button */
.filter-menu-header button:before {
  content: "\00d7"; /* Unicode X character */
}

.range-selector {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.range-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust the spacing between options */
}

/* This is to hide the radio button's default appearance,
   you might need to adjust it based on your design requirements. */
input[type="radio"] {
  opacity: 0;
  position: absolute;
}

input[type="radio"] + label {
  display: flex;
  align-items: center;
  margin-left: 5px; /* Spacing between radio button and label */
}

/* Optional: if you want to create a custom radio button appearance */
input[type="radio"] + label:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin-right: 0.5em;
  background-color: #fff;
}

input[type="radio"]:checked + label:before {
  background-color: blue; /* Color when the radio is selected */
}

.range-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Adjust as needed */
}

.range-inputs input[type="number"] {
  border: 1px solid #ccc;
  border-radius: 20px; /* Creates rounded corners */
  padding: 5px 10px;
  text-align: center;
  width: calc(50% - 20px); /* Adjust width as needed, accounting for the gap */
  margin: 0 5px; /* Creates space between the inputs */
}

.range-inputs span {
  display: inline-block;
  padding: 0 5px;
}

.sort-modal {
  position: absolute;
  margin-top: 10px;
  top: 100%; /* Below the button */
  left: 50%; /* Start at the half-width of the parent */
  transform: translateX(-50%); /* Center it exactly */
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px; /* Or whatever width you prefer */
  padding: 20px;
  font-size: 14px;
}

.sort-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sort-modal-content label {
  display: block;
  margin: 10px 0;
}

.sort-modal-content input[type="radio"] {
  margin-right: 10px;
}

/* BOTTOM ROW CSS */

.bottom-row {
  display: flex;
  height: 90%; /* 90% of the parent flex-item's height */
  width: 100%; /* Full width */
}

.column {
  flex: 1; /* Ensures that each column takes up an equal amount of space */
  width: 50%; /* Explicitly defining the width is not necessary with flex: 1, but it's here for clarity */
  padding: 10px; /* Padding inside each column */
  height: 100%; /* Full height of the parent container (bottom-row) */
  overflow-y: auto; /* Automatically add a scrollbar when needed */
  box-sizing: border-box; /* Includes padding and border in the width and height */
}

.table-body-container {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(90% - 20px); /* Adjust the height as needed */
  box-sizing: border-box;
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: lightgrey #f0f0f0; /* thumb and track color */
}

.table-body-container::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

.table-body-container::-webkit-scrollbar-track {
  background: #f0f0f0; /* Color of the scrollbar track */
}

.table-body-container::-webkit-scrollbar-thumb {
  background-color: lightgrey; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: round corners on the scrollbar thumb */
  border: 1px solid transparent; /* Optional: scrollbar thumb border */
}

.checkboxes {
  border-collapse: collapse; /* Ensures borders don't double up */
  width: 100%;
  font-size: 14px;
  table-layout: fixed;
}

.checkboxes thead,
.checkboxes tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Ensure columns in tbody align with thead */
}

.checkboxes th,
.checkboxes td {
  width: 50%; /* Divides the table into two equal columns */
  overflow: hidden; /* Prevents content from spilling out of the cell */
  -ms-text-overflow: ellipsis; /* Adds an ellipsis if the content is too long */
  text-overflow: ellipsis; /* Adds an ellipsis if the content is too long */
  white-space: nowrap; /* Prevents content from wrapping to the next line */
}

.checkboxes td {
  border-top: 1px solid #e0e0e0; /* Adds a horizontal line between rows */
}

.checkboxes td,
.checkboxes th {
  padding: 8px; /* Add some padding */
  border-bottom: 1% solid lightgray; /* Add bottom border to every cell */
}

.checkboxes tr:last-child td {
  border-bottom: none; /* Remove bottom border from the last row */
}

.checkboxes td:nth-child(2) {
  text-align: center;
}

.checkbox-header {
  display: flex;
  justify-content: space-between;
}

.checkbox-item {
  display: flex;
  justify-content: space-between;
}

.checkbox-label {
  /* Truncate the label text if it's too long */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-label,
.checkbox-value {
  margin: 0 10px;
}

.loading-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* semi-transparent overlay */
  margin-bottom: 30px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}