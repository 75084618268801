.explorer-container {
  display: flex;
  flex-direction: column;
  height: 83vh;
  padding: 5px;
}

.filters-container {
  height: 6vh;
  margin-bottom: 10px;
}

.grid-container {
  flex-grow: 1;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
