.navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    /*background-color: #333;*/
    color: #f9f9f9;
    box-shadow: 0px 0px 8px rgba(70, 77, 242, 0.24);
    background-color: white;
}

.navbar-left,
.navbar-right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navbar-logo {
    margin-right: 20px;
    font-size: 24px;
    font-weight: bold;
}

/* default styling for non-active links */
.navbar a {
    display: flex;
    align-items: center;
    justify-content: center; /* Horizontally center items */
    gap: 8px;
    color: grey !important;
    padding: 5px 15px; /* padding to make room for the oval */
    border-radius: 25px; /* makes the border rounded */
    transition: all 0.3s ease; /* smooth transition for all properties */
    border: 1px solid transparent; /* transparent border to avoid content shift */
}

.navbar a svg path {
    fill: grey;
}

/* active styling */
.navbar .active {
    color: blue !important;   /* Important flag can ensure the styles are applied */
    border: 1px solid;
}

.navbar .active svg path {
    fill: blue;
}