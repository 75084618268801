.tooltip-content {
    background-color: rgba(0, 0, 0, 0.8); /* Black background with 80% opacity */
    color: white; /* White text */
    padding: 8px; /* Some padding around the text */
    border-radius: 4px; /* Optional: rounded corners */
    font-size: 12px; /* Optional: font size */
}

.chart-container {
    width: 100%;  /* Full width of the parent container */
    height: 100%; /* 85% of the viewport height */
    margin: auto; /* Center the chart if it doesn't reach max width */
    box-sizing: border-box; /* Include padding in the width and height */
}