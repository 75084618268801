.filter-button {
  background: none;
  border: none;
  cursor: pointer;
  /* Add more styles as needed */
}

.filter-button .fas {
  margin-right: 5px; /* Space between icon and any text if you add text */
}

.fas{
  font-size: 12px;
}
